$insta: #405de6;
$imdb: #deb522;
$actors: #d6000a;
$lacasting: #15579d;
$twitter: #1da1f2;
$black: black;
$hover: #d5aca9;

.grid-contact {
  display: grid;
  height: auto;
  margin-top: 0;

  ul {
	margin: 4rem 0;
  }
}

span {
  margin: 1rem;
}

ul {
  list-style-type: none;
  padding-left: 0;
  > li {
    margin-top: 1em;
  }
}

.img {
  max-width: 60vw;
  height: 90vh;
  object-fit: cover;
  display: block;
}

.agency-wrapper {
  display: grid;
  grid-template-columns: auto;
}

.type {
	font-weight: 600;
	margin: 0;
}

.management,
.commercial {
  margin: 2rem;
  font-size: 1.25rem;
}

.agency-info {
  margin: 3rem;
  text-align: center;
}

.agency-header {
  margin: 1rem;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: 500;
}

.agency-phone {
  color: $black;
}

.agency-phone:hover {
  color: $hover;
}

.agency-url {
  color: $black;
}

.agency-url:hover {
  color: $hover;
}

.personal-info {
  margin: 3rem;
  text-align: center;
  > li a span {
    margin: 3em;
  }
}

.personal-header {
  text-decoration: underline;
  text-transform: uppercase;
  margin: 1rem;
  font-weight: 500;
}

.insta,
.imdb,
.actors,
.lacasting,
.twitter,
.contact-email {
  font-family: "Playfair", serif;
  color: $black;
  margin: 0;
  padding: 0;
}

.contact-email:hover {
  color: $hover;
}

.insta:hover {
  color: $insta;
}

.imdb:hover {
  color: $imdb;
}

.actors:hover {
  color: $actors;
}

.lacasting:hover {
  color: $lacasting;
}

.twitter:hover {
  color: $twitter;
}

.contact-img {
  margin: 0 1em 1em 1em;
}


//------------------------------------------------------------------------------

@media (min-width: 1200px) {
  .grid-contact {
    grid-template-columns: 1fr 1fr;
  }

  .agency-header {
    font-size: 2vw;
  }

  .agency-name,
  .agent-name,
  .agency-phone,
  .agency-url {
    font-size: 1.25vw;
  }

  .personal-header {
    font-size: 3vw;
  }

  .insta,
  .imdb,
  .actors,
  .lacasting,
  .twitter,
  .contact-email {
    font-size: 1.25rem;
  }
}

// --------------------------------------------------------------------------------

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .agency-header,
  .personal-header {
    font-size: 6vw;
  }

  .agency-name,
  .agent-name,
  .agency-phone,
  .agency-url {
    font-size: 5vw;
  }

  .insta,
  .imdb,
  .actors,
  .lacasting,
  .twitter,
  .contact-email {
    font-size: 1.25rem;
  }

  .img {
    max-width: 100%;
    height: auto;
  }

  .agency-wrapper {
    display: grid;
    grid-template-columns: auto;
  }

  .management,
  .commercial {
    margin: 1rem;
	font-size: 1.3rem;
  }

  .personal-info {
	margin: .5rem;
  }
}

//-------------------------------------------------------------------------

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .agency-header,
  .personal-header {
    font-size: 5vw;
  }

  .agency-name,
  .agent-name,
  .agency-phone,
  .agency-url {
    font-size: 3vw;
  }

  .insta,
  .imdb,
  .actors,
  .lacasting,
  .twitter,
  .contact-email {
    font-size: 1.25rem;
  }

  .img {
    max-width: 100%;
    height: auto;
  }
}

//------------------------------------------------------------------------------

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .grid-contact {
    grid-template-columns: 1fr auto;
  }

  .agency-header,
  .personal-header {
    font-size: 3vw;
  }

  .agency-name,
  .agent-name,
  .agency-phone,
  .agency-url {
    font-size: 2vw;
  }

  .insta,
  .imdb,
  .actors,
  .lacasting,
  .twitter,
  .contact-email {
    font-size: 1.25rem;
  }
}
