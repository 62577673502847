
.headshots-grid {
	margin: 0 3em 3em 3em;
}

// ------------------------------------------------------------------------------------

@media (min-width: 1200px) {
	.headshots-grid {
		margin-left: 10rem;
		margin-right: 10rem;
	}

	img.react-images__view-image--isModal {
		height: 100vh;
	}
}



