
$PlayfairDisplay: "Playfair", serif;

.wrapper-home {
	display: grid;
	margin-top: 0;
	max-width: 1920px;
}

.speedReel {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
}

.reelIframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
	border: 0;
}

.home-imgDiv {
	margin: 0 2em;
}

.home-img {
	height: 90vh;
	object-fit: cover;
	display: block;
    margin: auto;
}

.rightCol {
	margin-top: 0;
	overflow-y: hidden;
}

mark {
	color: black;
	background-color: #d5aca9;
}

.homeDesc > p {
	margin-left: 0;
	line-height: 1.6em;
	font-size: 1.5em;
}

//------------------------------------------------------------------------------

@media (min-width: 1200px) {
	.wrapper-home {
		grid-template-columns: auto auto;
	}

	.rightCol {
		max-width: 900px;
		display: block;
		margin: auto;
		margin-top: 0;
		padding-right: 2em;
		padding-bottom: 2em;
	}

	.homeDesc {
		font-family: $PlayfairDisplay;
		font-size: 18px;
		margin-top: 1.0em;
	}

	.firstCharacter {
		color: #dc4741;
		float: left;
		font-family: $PlayfairDisplay;
		line-height: .5em;
		font-size: 5.5em;
	}		
}

//------------------------------------------------------------------------------

@media only screen and (min-width: 320px) and (max-width: 767px) {
	.wrapper-home {
		grid-template-rows: 1fr;
	}

	.rightCol {
		margin: 2em;
	}

	.home-imgDiv {
		margin-bottom: 0;
	}

	.home-img {
		width: 100%;
		height: auto;
	}

	.homeDesc {
		font-family: $PlayfairDisplay;
		font-size: 1.2em;
		text-align: center;
	}

	.firstCharacter {
		margin: 0;
	}
}

//------------------------------------------------------------------------------

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.wrapper-home {
		grid-template-rows: 1fr;
	}

	.rightCol {
		margin: 2em;
	}

	.home-img {
		display: none;
	}

	.homeDesc {
		font-family: $PlayfairDisplay;
		font-size: 3vw;
		text-align: center;
	}

	.firstCharacter {
		margin: 0;
	}
}

//------------------------------------------------------------------------------

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
	.wrapper-home {
		grid-template-rows: 1fr;
	}

	.rightCol {
		margin: 2em;
	}

	.home-img {
		display: none;
	}

	.homeDesc {
		font-family: $PlayfairDisplay;
		font-size: 2.5vw;
		text-align: center;
	}

	.firstCharacter {
		margin: 0;
	}
}

