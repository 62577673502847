.navbar {
	display: flex;
	overflow: hidden;
	margin: 0 2rem;
	max-width: 1920px;
	justify-content: space-between;
	align-items: stretch;
}

.content {
	max-width: 1920px;
	margin: auto;
	overflow-x: hidden;
}

.menuItems li a {
	color: rgb(0, 0, 0);
	text-decoration: none;
	font-family: "Montserrat", sans-serif;
	font-weight: normal;
	text-transform: uppercase;
}

.navbarBrand p {
	font-family: "Montserrat", sans-serif;
	display: inline-block;
	color: black;
	text-decoration: none;
	font-weight: normal;
	text-transform: uppercase;
}


.menuItems li a:hover {
	color: #d5aca9;
}

.menuItems li a:focus:after {
	width: 100%;
	left: 0;
}

.fourOhFour {
	display: flex;
  	align-items: center;
 	justify-content: center;
	height: 100vh;
}

//---------------------------------------------------------------------------

@media (min-width: 1200px) {
	.navbar {
		flex: 1;
	}

	.menuItems li {
		margin-top: 1em;
		float: left;
		margin-right: 1em;
	}

	.menuItems {
		text-align: right;
	}

	.menuItems ul {
		margin: 0;
		padding: 0;
	}

	.navbarBrand {
		font-size: 1.7em;
	}

	.menuItems li a {
		margin: 1em;
	}

	.mobileMenu {
		display: none;
		visibility: hidden;
	}
}

//---------------------------------------------------------------------------

@media only screen and (min-width: 320px) and (max-width: 1199px) {
	.navbarBrand {
		width: 75%;
		font-size: 1.3em;
	}

	.menuItems li {
		text-align: center;
	}

	.menuItems {
		text-align: center;
		display: none;
	}

	.bm-burger-button {
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		font-size: 1.2em;
	}

	.mobileMenu {
		height: 100%;
	}
}


//----------------------------------------------------------

/* Position and sizing of burger button */
.right .bm-burger-button, .bm-burger-button:focus {
	position: absolute;
	width: 65px;
	height: 22px;
	left: 86%;
    right: .5em;
	top: 1em;
	outline: none;
	display: inline-block;
	text-align: center;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
	background: #000000;
	height: 10% !important;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
	background: #d5aca9;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
	display: none;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
	width: 240px !important;
	bottom: 0px;
  }

  /* General sidebar styles */
  .bm-menu {
	background: #edcfb6;
	padding: 0 !important;
	font-size: 1.5em;
	scrollbar-width: none;
	overflow: hidden !important;
  }

  .bm-menu::-webkit-scrollbar {
	display: none; 
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
	fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
	color: #b8b7ad;
	padding: 0.8em;
	box-sizing: border-box;
    overflow: auto;
  }
  
  /* Individual item */
  .bm-item {
	display: inline-block;
	font-family: "Montserrat", sans-serif;
	padding: 0.5em;
	text-decoration: none;
	color: black;
	outline: none;
  }

  .bm-item:hover {
	color: #614344;
  }
  
  /* Styling of overlay */
  .bm-overlay {
	background: rgba(0, 0, 0, 0.3);
	height: 100% !important;
	padding-top: 0 !important;
	margin-top: -16px !important;
  }

  .bm-cross-button{
	display: none;
  }

  #react-burger-menu-btn {
	  height: 200% !important;
  }
