
$border: 1em solid white;

@font-face {
	font-family: "Montserrat";
	src: url("static/fonts/Montserrat/Montserrat-Regular.woff") format("woff");
	font-display: swap;
}
@font-face {
	font-family: "Playfair";
	src: url("static/fonts/Playfair/PlayfairDisplay-Regular.woff") format("woff");
	font-display: swap;
}

html {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ffede0;
	text-rendering: auto;
	box-sizing: border-box;
}

::selection {
	background: #d5aca9;
}

body::-webkit-scrollbar-track {
	background-color:#fff;
}

body::-webkit-scrollbar-thumb {
	background-color: #d5aca9;
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

body::-webkit-scrollbar {
	width: 12px;
	background-color:#fff;
}
