@import 'node_modules/react-modal-video/scss/modal-video.scss';

$font: "Montserrat", sans-serif;
$fontSize: 6vw;
$PlayfairDisplay: "Playfair", serif;

.modal-video {
	background-color: rgba(0,0,0,0.8);;
}


hr {
	border: 1px solid black;
    margin: 2em;
    width: 75%;
    margin: 1em auto;
}

.section-title {
	display: block;
	font-family: $PlayfairDisplay;
	font-weight: 600;
	font-size: 2em;
	text-align: center;
}

.voiceOver-text {
	display: block;
	font-family: $PlayfairDisplay;
	color: red;
	font-weight: 600;
	font-size: 2em;
}

.reels-title, .voiceOvers-title {
	margin-left: 1.4em;
	margin-bottom: 0;
	font-family: $PlayfairDisplay;
	font-size: 1.5em;
	text-decoration: underline;
	
}

.voiceOver-mp3 {
	width: 100%; 
	border-radius: 15px;
	margin-bottom: 4em;

	&::-webkit-media-controls-panel  {
		background: #ffede0;
	}
	&::-webkit-media-controls-timeline {
		color: #d5aca9;
	}
	&:focus {
		  outline: none;
	}
}

.voiceOvers-grid {
	display: grid;
	height: auto;
	margin: 2rem;
	cursor: pointer;
}

.video-grid {
	display: grid;
	height: auto;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	text-align: center;
	margin-top: 0;
}

.thumb {
	opacity: 1;
	display: block;
	width: 100%;
	height: auto;
	transition: .5s ease;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

//-----------------------------------------------------------------------------

@media (min-width: 1200px) {
	.video-grid {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 5px 5px;
	}

	.voiceOvers-grid {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 5px 5px;
	}

	.videoOverlay {
		position: relative;
	}

	.videoOverlay .caption {
		position: absolute;		
		transform: translate(-50%, 25%);
		z-index: 10;
    	opacity: 0;
    	transition: all 0.8s ease;	
		font-family: $font;
		font-size: $fontSize;
		font-weight: 600;
		color: white;
	}

	.videoOverlay .caption span {
		margin: 0;
		color: white;
	}

	.videoOverlay:hover .caption {
		opacity: 1;
	}

	.mobile {
		display: none;
	}
}

//-----------------------------------------------------------------------------

@media only screen and (min-width: 320px) and (max-width: 767px) {
	.video-grid {
		grid-template-rows: 1fr;
	}

	.caption {
		display: none;
	}

	.thumb {
		display: none;
	}

	.mobile {
		width: 100%;
	}

	.theatricalText,
	.comedicText,
	.combatText,
	.russianText,
	.play  {
		display: none;
	}

	.voiceOver-text {
		font-size: 1.5em;
	}
}

//------------------------------------------------------------------------------

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.video-grid {
		grid-template-rows: 1fr;
	}

	.caption {
		display: none;
	}

	.thumb {
		display: none;
	}

	.mobile {
		width: 100%;
		cursor: pointer;
	}

	.theatricalText,
	.comedicText,
	.combatText,
	.russianText,
	.play  {
		display: none;
	}
}

//------------------------------------------------------------------------------

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
	.video-grid {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 5px 5px;
	}

	.caption {
		display: none;
	}

	.thumb {
		display: none;
	}

	.mobile {
		width: 100%;
	}

	.theatricalText,
	.comedicText,
	.combatText,
	.russianText,
	.play  {
		display: none;
	}
}
